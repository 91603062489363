import Cookies from "universal-cookie";
import { returnEmail } from "./utils";
const SystemConstant = {
  ImageUrlPrefix: process.env.REACT_APP_BASE_URL + "pub/media/catalog/product",
  /*ImageUrlPrefixLocal: 'http://unbxd.nagarro.local/pub/media/catalog/product',
    ProductDetail: 'http://mpoc/rest/V1/products/',
    GuestCart: 'http://mpoc/rest/V1/guest-carts/',
    GuestCartUpdate: 'http://mpoc/rest/V1/guest-carts/',
    GuestShippingInformation: 'http://mpoc/rest/default/V1/guest-carts/',
    GuestOrder: 'http://mpoc/rest/default/V1/guest-carts/',
    cmsPage: 'http://mpoc/rest/default/V1/cmsPage/',

    userRegistration: 'http://unbxd.nagarro.local/rest/default/V1/customers',
    //AddtoCartGuest: 'rest/V1/guest-carts/4522ebc7d219rre415a8ad1eewr22f73b55/items'
    //bgkih9kv41ut0ug0z6mjoe1r1i513w1k*/
  RequestHeadersLocal: {
    Authorization: "Bearer 123",

    "Content-Type": "application/json",

    "Access-Control-Allow-Origin": "localhost",
  },

  adminRequestHeaders: {
    Authorization:
      "Bearer eyJraWQiOiIxIiwiYWxnIjoiSFMyNTYifQ.eyJ1aWQiOjEsInV0eXBpZCI6MiwiaWF0IjoxNjU0MjY4NzYxLCJleHAiOjM2MDAxNjU0MjY4NzYxfQ.R3W5uDJd72p6QPsaRTLAJKO-rP7tcpR6P1-pZdKeF4o",

    "Content-Type": "application/json",

    "Access-Control-Allow-Origin": "localhost",
  },
  getUserRequestHeaders: function () {
    let cookies = new Cookies();
    let requestToken = cookies.get("cartSession");
    //requestToken='Bearer eyJraWQiOiIxIiwiYWxnIjoiSFMyNTYifQ.eyJ1aWQiOjEsInV0eXBpZCI6MiwiaWF0IjoxNjUxNjY1NzQ5LCJleHAiOjE2NTE2NjkzNDl9.1Nu6IampR-Bm5-JAa_MdKfR5kwJCPiHYo2h9xbf5_Uk';
    return {
      Authorization: `Bearer ${requestToken}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "localhost",
    };
  },
  cookies: new Cookies(),
  //Revert Specific domain changes as email will be verfied from database
  validEmailRegex: RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  ),
  validPasswordRegex: RegExp(
    /^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/i
  ),
  countryList: {
    1140: "UAE",
    9110: "KSA",
  },
  allText: 'All',
  emailDomain: process.env.REACT_APP_EMAIL_DOMAIN,
  defaultImage: process.env.REACT_APP_DEFAULT_PRODUCT_IMAGE,
  emailValidationInfo:
    "Please enter company email id.",
  emailValidationInfonew:
    "Please enter company email id.",
  viewProductAdditionalDetailsText: "Additional Details:",
  descriptionPayment: "Ordered goods",
  userNameForPayment: "Redington",
  emailForPayment: process.env.REACT_APP_EMAIL_FOR_PAYMENT,
  phoneNumberForPayment: "+966118352514",
  address1ForPayment:
    "Redington Saudi Arabia Distribution Company, Block 16, Al Fursan Warehouse complex",
  address2ForPayment:
    "Plot no. 145 to 152, Istanbul street,Exit-18, Sulai,Riyadh, KSA, P.O Box 66120 Postal code 14328",
  displayControlBillingAddress: "HIDE",
  displayControlCustomerEmail: "HIDE",
  displayControlPaymentTerms: "HIDE",
  paymentMerchantId: process.env.REACT_APP_MERCHANT_ID,
  mpgsTimeout: process.env.REACT_APP_MPGS_TIMEOUT,
  paymentTimeOut: "Payment Session Timed Out. Please try again after sometime.",
  lenovoHeading: "About Us",
  aboutUsText: "About Us",
  aboutUsBodyText: "This is About Us Screen",
  addressText: "Address:",
  cityText: "City:",
  regionText: "Region:",
  regionWithoutColonText: "Region",
  postCodeText: "Postcode:",
  countryText: "Country:",
  countryWithoutColonText: "Country",
  telephoneText: "Telephone:",
  editText: "Edit",
  emiratesMembershipIdText: "Emirates Membership ID",
  emiratesMembershipIdError: "Emirates Membership ID must be 15 digits",
  deleteText: "Delete",
  addMoreAddressText: "Add More Address",
  addAddressText: "Add Address",
  addNewText: "Add New",
  addressTextWithoutColon: "Address",
  firstNameText: "First Name",
  firstNamePlaceHolder: "Enter First Name",
  lastNameText: "Last Name",
  lastNamePlaceHolder: "Enter Last Name",
  streetAddressPlaceHolder: "Enter Street Address",
  cityPlaceHolder: "Enter City",
  postCodePlaceHolder: "Enter Post Code",
  phoneNumberText: "Phone No.",
  phoneNumberPlaceholder: "Enter Phone Number",
  selectACountryText: "Select a Country",
  selectARegionText: "Select a region",
  noRegionFound: "No Region Found!",
  selectACountryFirstText: "Please select a country first",
  saveAddressText: "Save Address",
  firstNameValidationText: "Please enter firstname!",
  lastNameValidationText: "Please enter lastname!",
  addressValidationText: "Please enter an address!",
  cityValidationText: "Please enter a city!",
  mobileNumberValidationText: "Please enter mobile number!",
  mobileNumberLengthValidationText: "Please enter valid mobile number",
  postCodeValidationText: "Please enter post code!",
  postCodeLengthValidationText: "Please enter valid post code",
  subscriptionIdValidationText: "Please enter valid subscription id",
  subscriptionIdInvalid: "Subscription Code not found or inactive",
  countryValidationText: "Please Select Country!",
  regionValidationText: "Please Select Region!",
  mySavedAddressText: "My Saved Addresses",
  returnManagementText: "Return Management",
  customerServiceText: "Customer Service",
  myProfileText: "My Profile",
  orderHistoryText: "Order History",
  emailAddressText: "Email address",
  emailAddressPlaceHolder: "Enter email",
  mobileNumberText: "Mobile Number",
  mobileNumberPlaceHolder: "Enter Mobile Number",
  subscriptionIdText: "Subscription ID",
  subscriptionPlaceHolder: "Enter Subscription Id",
  registerWithSubscriptionID: "Register with Subscription ID",
  saveProfileText: "Save Profile",
  pleaseEnterRemarkText: "Please enter your remarks.",
  checkoutOrderHistoryText: "Checkout Your order history here!",
  totalOrderText: "Total Orders:",
  orderIdText: "Order ID:",
  orderIdTextWithoutColon: "Order ID",
  totalItemsText: "Total Items:",
  statusText: "Status:",
  totalValueSartext: (currenyCode = "SAR") => {
    return `Total Value: ${currenyCode}`;
  },
  totalValue: "Total Value:",
  orderTrackingText: "Order Tracking",
  invoiceText: "Invoice",
  productText: "PRODUCT",
  quantityOrderedText: "QUANTITY ORDERED",
  unitPriceText: "UNIT PRICE",
  totalPriceText: "TOTAL PRICE",
  returnDateText: "RETURN DATE",
  confirmReturnDate: "Confirm Return",
  itemTotalText: "Item Total:",
  sarText: (currencyCode = "SAR") => {
    return currencyCode;
  },
  taxText: "Tax:",
  totalText: "Total:",
  noOrdersText: "No Orders",
  noCustomerService: "Details Not Available",
  helloThereText: "Hello there!",
  changeYourCurrentPassword: "Change your current password here.",
  currentPasswordText: "Current Password",
  newPasswordText: "New Password",
  confirmNewPasswordText: "Confirm New Password",
  productTitleCaseText: "Product",
  passwordCharacterValidation:
    "Password must be of 8-16 characters long and must have atleast one digit upper case letter and one special character",
  updateText: "UPDATE",
  qtyText: "Qty",
  unitPriceTitleCaseText: "Unit Price",
  remarkText: "Remarks",
  skuText: "SKU:",
  noReturnsRequested: "You do not have returns requested",
  loadingText: "Loading...",
  trackingIdForOrderText: "Tracking Id for this order is",
  trackingDetailsText: "Tracking Details",
  confirmYourRerquest: "Please confirm your return request.",
  cancelText: "Cancel",
  quantityText: "Quantity:",
  increaseText: "Increase",
  decreaseText: "Decrease",
  addToCartText: "Add To Cart",
  outOfStockText: "Out of Stock",
  knowUsText: "KNOW US",
  policiesText: "POLICIES",
  contactUsText: "CONTACT US",
  footerCompanyText: "2022 Redingtongroup.com. All rights reserved",
  homeText: "Home",
  hiText: "Hi,",
  logoutText: "Logout",
  //variables name signin will be changed only when all signin reference will be changed.
  signInText: "Login",
  myAccountText: "My Account",
  signUpInsteadText: "Signup instead?",
  myCartText: "My Cart",
  signUpText: "Signup",
  signInTitleCaseText: "Login",
  lenovoText: "Contact Us",
  contactUsTitleCaseText: "Contact Us",
  supportEmailText: "Support Email:",
  emailAddressRealText: "SupportB2C@redingtongroup.com",
  phoneNumberFullText: "Phone Number:",
  phoneNumberRealText: "+971 4 516 1000",
  addressRealText: `Redington Middle East LLC
  Redington Gulf Representative Office, Level 16
  Burjuman Business Tower - Sheikh Khalifa Bin Zayed St - Al Mankhool
  Dubai - United Arab Emirates`,
  redingtonVatNumber: "VAT Reg No:301153802100003",
  lenovoFaqTitleText: "FAQs",
  faqText: "FAQs",
  faqScreenText: "This is FAQs Screen",
  pleaseEnterEmailText: "Please enter an email address!",
  emailNotValidText: "Email is not valid!",
  lenovoForgotPassword: "Forget Password",
  forgotPasswordText: "Forgot Password",
  enterEmailAddressText: "Enter Email Address",
  sendOtpText: "Send OTP",
  pleaseEnterPasswordText: "Please enter password!",
  pleaseEnterConfirmPasswordText: "Please enter an confirm password",
  confirmPasswordAndPasswordNotSame:
    "Password and confirm password is not same!",
  passwordErrorText:
    "Please enter a password with 8 or more than 8 characters with atleast 1 lower case, 1 upper case, 1 digit and 1 special character.",
  passwordChangedSuccess: "Password change successfully.",
  enterValidCaptcha: "Please enter valid Captcha.",
  incorrectCaptcha: "Captcha is incorrect. Please try again.",
  lenovoSignin: "Login",
  enterNewPasswordText: "Enter new Password",
  confirmPasswordText: "Confirm Password",
  reEnterPassword: "Re Enter new Password",
  confirmText: "Confirm",
  whyRegisterWithUsText: "Why Register With Us?",
  lenovoB2cHome: "Home",
  recommendedForYouText: "Recommended For You",
  recommendationsText: "Featured Products",
  addToCart: "Add To Cart",
  outOfStock: "Out of Stock",
  lenovoResultsText: "Search Results for",
  showingResultText: "Showing results for:",
  noResultsFoundText: "No results found!",
  lenovoB2cNotFoundText: "Not Found",
  pageNotFoundText: "404 - Page Not Found!",
  pageNotExistText: "The page that you are trying to access does not exist.",
  backToHomeText: "Let's take you back to home.",
  goToHomeText: "Go To Home",
  lenovoOrderSuccessText: "Order Success",
  orderPlacedWithOrderIdText: "Order placed successfully! Order id:",
  viewOrdersText: "View Orders",
  continueShoppingText: "Continue Shopping",
  lenovoOtpVerificationText: "OTP Verification",
  otpVerificationText: "OTP Verification",
  otpSendToTextForEmail: "Your OTP has been sent to",
  otpSendToTextForEmailAndMobile:
    "Your OTP has been sent to below email ID and registered mobile number (Please enter any one).",
  enterOtpText: "Enter OTP",
  verifyOtpText: "Verify OTP",
  resendOtpText: "Resend OTP",
  validQuantityText: "Please enter a valid quantity.",
  noProductFoundText: "No product found!",
  noDealsFoundText: "No deals found!",
  descriptionText: "Description",
  sizeText: "Size",
  colorText: "Color",
  priceInclusiveOfVatText: "Price Inclusive of VAT",
  lenovoReturnPolicyText: "Return Policy",
  returnPolicyText: "Return Policy",
  returnPolicyScreenText: "This is Return Policy Screen",
  passwordText: "Password",
  titleValidationText: "Please enter title!",
  customerRefisteredOtpText:
    "Customer registered successfully! Please verify OTP!",
  lenovoB2cSignUp: "Sign Up",
  titleText: "Title",
  selectTitleText: "Title",
  enterPasswordText: "Enter Password",
  mobileNumberPrefixText: "+966",
  lenovoTermsAndConditionsText: "Terms And Conditions",
  termsAndConditionsText: "Terms And Conditions",
  termsAndConditionsScreenText: "This is Terms And Conditions Screen",
  checkoutText: "Checkout",
  streetAddressText: "Street Address",
  cityWithoutColonText: "City",
  stateText: "State",
  postCodeWithoutColonText: "Post Code",
  orderSummaryText: "Order Summary",
  totalItemsWithoutColonText: "TOTAL ITEMS",
  totalItemCostText: "TOTAL ITEM COST",
  shippingRateText: "SHIPPING RATE",
  totalCostText: "TOTAL COST",
  payThroughText: "PAY THROUGH:",
  placeOrderText: "Place Order",
  orderPlacesSuccessfullyText: "Order is placed successfully. Order Id:",
  lenovoB2cMycartText: "My Cart",
  shoppingCart: "Shopping Cart",
  quantityWithoutColonText: "Quantity",
  itemsText: "Items",
  subtotalText: "Subtotal",
  couponApplied: "Coupon discount",
  haveACoupon: "Have a coupon?",
  invalidCoupon: "Invalid Coupon Code",
  emptyCartText: "You don't have any items in your cart!",
  favouriteItemsText: "Your favourite items are just a click away!",
  summaryText: "Summary",
  cartTotalSarText: (currencyCode = "SAR") => {
    return `Cart Total: ${currencyCode}`;
  },
  someErrorOccuredText: "Some Error occured while placing order!",
  selectPaymentText: "Please select a payment method before proceeding.",
  pleaseAddAddressText: "Please add address before proceeding.",
  updatedText: "Updated",
  shippingAndHandlingText: `Shipping & Handling`,
  taxWithoutColonText: "Tax",
  grandTotalText: "Grand Total",
  somethingWentWrongText: "Something went wrong!",
  couldNotUpdateAddressText: " Could not update address.",
  profileUpdatedSuccessfully: "My Profile Updated successfully!",
  failedToUpdateProfile: "Failed to update My Profile!",
  couldNotUpdateProfileText: "Could not update profile.",
  failedToGetOrdersText: "Failed to get orders!",
  failedToGetCartItemsText: "Failed to fetch cart items!",
  couldNotGetOrderDetailsText: "Could not get order details.",
  failedToGetCategoryList: "Failed to retrieve the category list!",
  couldNotSearchProducts: "Could not search products.",
  couldNotGetCategoriesText: "Could not get categories.",
  couldNotDeleteProduct: "Could not delete product.",
  couldNotGetColorListText: "Could not get color list.",
  couldNotGetSizeListText: "Could not get size list.",
  couldNotEstimateShippingMethod: "Could not estimate shipping method.",
  couldNotGetShippingInformation: "Could not get shipping information.",
  couldNotPlaceOrder: "Could not place order.",
  couldNotUpdatePassword: "Could not update password.",
  otpSentCheckEmail: "OTP sent. Please check email.",
  otpSentCheckEmailAndMobile: "OTP sent. Please check email and mobile.",
  otpFailedCheckMail: "OTP sent failed. Please try again.",
  couldNotGetProducts: "Could not get products.",
  productAddedSuccessfullyText: "Product Added Successfully!",
  enquirySendSuccessfully:
    "Your enquiry has been received and we will notify once the product is available in stock.",
  failedToAddItemsToCart: "Failed to add item to cart!",
  productQuantityUpdatedSuccessfullyText:
    "Product quantity updated successfully!",
  couldNotGetAdditionalDetailsText: "Could not get additional details.",
  productReturnSuccessfullText: "Product return successful",
  couldNotReturnProductText: "Could not return product",
  invoiceSuccessText: "Invoice generated successfully!",
  failedToLoginText: "Failed to login!",
  cartTokenFailedText: "Could not get cart token.",
  customerDetailsFailedText: "Could not get customer details.",
  guestUserCartMergeFailedText: "Could not merge guest and user cart.",
  countryListFailedText: "Could not get country list.",
  regionListFailedText: "Could not get region list.",
  registerUserFailedText: "Could not register user",
  couldNotGetShippingEstimateText: "Could not estimate shipping info.",
  orderCreateFailedText: "Could not create order.",
  shortDescriptionBlankMessage:
    "Description is not available for this product.",
  additionalDescriptionBlankDescription:
    "Additional details are not available for this product.",
  lenovoB2cLenovoCheckout: "User Checkout",
  clickOnThis: "Click on this",
  toTrackYourOrder: "to track your order.",
  link: "link",
  paymentUnsuccessful: "Could not complete the payment.",
  transactionReferenceNumber: "Your transaction reference number is:",
  successfulOrder:
    "Your payment has been received successfully for order with Order id:",
  lenovoB2bSuccess: "Payment Success",
  unsuccessfullInvoice: "Could not generate invoice",
  note: "Note:",
  pageReloadWarning:
    "Please do not reload page or click on back button or navigate to any other page.",
  enterCardDetails: "Enter Card Details here",
  lenovoB2cPaymentCancel: "Payment Cancel",
  userPaymentCancel: "You have canceled  payment!!!",
  lenovoB2cPaymentError: "Payment Error",
  oopsError: "Oops!! Some Error Occured. Please try again.",
  paymentProceed: "Proceed To Payment Alert.",
  userPaymentConfirm:
    "Thank You for selecting the products, you are moving to payment gateway. Once you proceed, your cart will become empty.",
  close: "Close",
  lenovoB2cPaymentTimeout: "Payment Session Timeout",
  paymentTimeout: "Payment Session Timeout",
  productDetails: "Product Details",
  totalItems: "Total Items",
  paymentMethod: "Payment Method:",
  selectPaymentMethod: "Select a payment method",
  termsPolicyAtCheckout: "I've read and accept the",
  proceedToPay: "Proceed To Pay",
  vat: "VAT",
  successfully: "successfully!",
  customerServiceTollFreeNumber: process.env.CUSTOMER_SERVICE_TOLL_FREE_NUMBER,
  customerServiceTollFreeNumberText: "For Premium product - TOLL Fee Number",
  iAccept: "I accept",
  defaultRegionId: "1120",
  defaultCountryId: "SA",
  defaultRegion: "Riyadh",
  defaultRegionCode: "RI",
  defaultPostCode: "12223",
  warning: "warning",
  success: "success",
  danger: "danger",
  info: "info",
  light: "light",
  backButtonNotAllowed: "You cannot press back button",
  awbNumberError: "AWB number is not generated",
  colorSelect: "Please select a color for the product.",
  sizeSelect: "Please select a size for the product.",
  uiPaymentSecondTimeout: process.env.REACT_APP_UI_PAYMENT_SECOND_TIMEOUT,
  uiPaymentMinuteTimeout: process.env.REACT_APP_UI_PAYMENT_MINUTE_TIMEOUT,
  uiPaymentHourTimeout: process.env.REACT_APP_UI_PAYMENT_HOUR_TIMEOUT,
  error: "error",
  failedToSearchProduct: "Failed to search products!",
  failedRetrieveSession: "Failed to retrieve session!",
  userSessionNotPresent: "User session not present ",
  bannerImageError: "Could not get banner images.",
  termsAndConditions:
    "Please accept the terms and conditions checkbox before proceeding",
  recommendedProductError: "Could not get recommended products.",
  signupURLEndpoint: "/signup",
  register: "register",
  defaultStreet: "...",
  paymentProceedWithDefaultStreet: "Please provide complete delivery address",
  paymentProceedWithDefaultStreetBody:
    "Please complete the delivery address by editing selected address or add new address.",
  customerService: {
    phone: "800 844 8459",
    line1: "Premier call center: ",
    line2: "Languages: Arabic,English",
    line3:
      "Hours of operation 10.00 - 18.00 Sunday - Thursday (After hours support available 7 days a week in English)",
    line4: "BrandThink Products, Smart",
  },
  customerServiceAddress1: {
    customerServiceAddress1Text: "Riyadh:",
    address1: {
      titleText: "Ensure Services Arabia co LLC Address:",
      addressText: `Shop no. 100, Building 9, Shadaad Bin Ous, Olaya, Riyadh,
      Saudi Arabia - 12611 (Burj Al Hayat Suites)`,
    },
    address2: {
      titleText: "Ensure Services Arabia LLC Address:",
      addressText: `Al Jazirah, 4156, Saudi Arabia, Sulaya, Riyadh 14262, Saudi
      Arabia`,
    },
    telephone: "Telephone: +966920033446",
    email: "Email: riyadh@ensureservices.com",
    timing: "Timings: 09:00am-6:00pm Sunday to Thursday.",
  },
  customerServiceAddress2: {
    customerServiceAddress2Text: "Jeddah:",
    address: {
      titleText: "Ensure Services Arabia LLC Address:",
      addressText: `Computer City, Opp. National Guard, Palestine Street, Jeddah`,
    },
    telephone: "Telephone: +966920033446",
    email: "Email: Jeddah@ensureservices.com",
    timing: "Timings: 09:00am- 6:00pm Sunday to Thursday.",
  },
  customerServiceAddress3: {
    customerServiceAddress3Text: "Khobar/Dammam:",
    address: {
      titleText: "Ensure Services Arabia LLC Address:",
      addressText: `(shifted to a new location) Showroom no: 6 Business Gate,
      Khobar Dammam Highway , Al Khalidiyah Ash Shamaliyah, Dammam
      32232`,
    },
    telephone: "Telephone: +966920033446",
    email: "Email: khobar@ensureservices.com",
    timing: "Timings: 09:00am- 6:00pm Sunday to Thursday.",
  },
  footerContent: {
    knowUsArr: [
      {
        text: "About Us",
        link: "/aboutus",
      },
      {
        text: "FAQs",
        link: "/faqs",
      },
    ],
    policiesArr: [
      {
        text: "Return Policy",
        link: "/returnpolicy",
      },
      {
        text: "Privacy Policy",
        type: "link",
        link: "/privacypolicy",
      },
    ],
    contactArr: [
      {
        text: "Contact Us",
        link: "/contactus",
      },
    ],
  },
  emailDomainRetrivalFailed: "Could not get email domain for selected country.",
  invalidSubscriptionCode: "Subscription code is invalid.",
  emailDomainError:
    "Please select a different country since email domain is not available for this country",
  regionListError: "Please select different country! no region avaiable",
  fallbackImg:
    "pub/media/catalog/product/placeholder/default/thumbnail_green.jpg",
  resetText: "Reset",
  inValidProductItem: "Items have exceeded the maximum allowed quantity",
  outOfStock: "Out of Stock",
  sendEnquiry: "Send Enquiry",
  removeInvalidItemFromCart:
    "Some items with invalid prices have been removed from your cart.",
  DEVICE_SIZES: {
    SMALL: 576, // Small devices (phones)
    MEDIUM: 768, // Medium devices (tablets)
    LARGE: 992, // Large devices (desktops)
    EXTRA_LARGE: 1200, // Extra large devices (large desktops)
  },
};

export default SystemConstant;

/*
http://unbxd.nagarro.local/rest/V1/integration/customer/token
{
        "username": baby.mayra@nagarro.com,
        "password": "admin@123"
    }
    Bearer: bgkih9kv41ut0ug0z6mjoe1r1i513w1k

    return : 5b4cv6jr6g63v4eox8bnj92uuvtq3uck



Customer Information : http://unbxd.nagarro.local/rest/V1/customers/me

Bearer: 5b4cv6jr6g63v4eox8bnj92uuvtq3uck

List of countries:
http://unbxd.nagarro.local/rest/default/V1/directory/countries

*/
