import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import SystemConstant from "constants/SystemConstant";
import { checkAuth, removeUserCookie } from "constants/utils";
import { useDispatch } from "react-redux";
import { getSession } from "actions/cartActions";
import { clearUserData } from "actions/signinActions";

const Privateroute = (props) => {
  //todo Finalize authorize mechanism
  const dispatch = useDispatch();
  const session_token = SystemConstant.cookies.get("userCartToken");
  const cartSession = SystemConstant.cookies.get("cartSession");
  const domainVerify = SystemConstant.cookies.get("domainVerify");

  // Connection to a broadcast channel
  const broadcastChannel = new BroadcastChannel("lenovoB2C_channel");
  // A handler that only logs the event to the console:
  broadcastChannel.onmessage = (event) => {
    window.location.reload();
  };

  function tokenExpiry(token) {
    const response = checkAuth(token);
    if (!response) {
      removeUserCookie();
      dispatch(getSession());
      dispatch(clearUserData());
    }
    return response;
  }
  const isGuestUser = domainVerify === "true";

  if (isGuestUser) {
    return <Outlet />;
  }
  return props.props === true ? (
    session_token && tokenExpiry(cartSession) ? (
      <Outlet />
    ) : (
      <Navigate to="/home" />
    )
  ) : session_token && tokenExpiry(cartSession) ? (
    <Navigate to="/home" />
  ) : (
    <Outlet />
  );
};

export default Privateroute;
