import React, { Suspense, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import AlertComponent from "components/common/Alert";
import Toaster from "components/common/Toast";
import { getSession } from "actions/cartActions";
import { getCategories, getVideoUrl } from "actions/appActions";
import Privateroute from "components/privateroute/privateroute";
import { checkAuth, removeUserCookie } from "constants/utils";
import SystemConstant from "constants/SystemConstant";
import { clearUserData } from "actions/signinActions";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { getUserInfo } from './actions/userInfoAction';
import { initializeTagManager } from "components/gtm/gtm";
import usePageTracking from "components/usePageTracking/usePageTracking";

const Home = React.lazy(() => import("./components/home"));
const Account = React.lazy(() => import("./components/account"));
const Products = React.lazy(() => import("./components/products"));
const ViewCart = React.lazy(() => import("./components/viewcart"));
const GuestCheckout = React.lazy(() =>
  import("./components/viewcart/GuestCheckout")
);
const ViewProduct = React.lazy(() =>
  import("./components/products/ViewProduct")
);
const Signup = React.lazy(() => import("./components/signup"));
const Signin = React.lazy(() => import("./components/signin"));
const UserCheckout = React.lazy(() =>
  import("./components/viewcart/UserCheckout")
);
const SearchPage = React.lazy(() => import("./components/home/searchPage"));
const Otp = React.lazy(() => import("./components/otp/otp"));
const Aboutus = React.lazy(() => import("./components/aboutus/aboutus"));
const Contactus = React.lazy(() => import("./components/contactus/contactus"));
const Faqs = React.lazy(() => import("./components/faqs/faqs"));
const Returnpolicy = React.lazy(() =>
  import("./components/returnpolicy/returnpolicy")
);
const Termsandconditions = React.lazy(() =>
  import("./components/termsandconditions/termsandconditions")
);
const Category = React.lazy(() => import("./components/categorylist/Category"));
const ForgetPassword = React.lazy(() => import("./components/forgetpassword"));
const NewPassword = React.lazy(() =>
  import("./components/forgetpassword/newpassword")
);
const Ordersuccess = React.lazy(() =>
  import("components/ordersuccess/ordersuccess")
);
const SessionTimeout = React.lazy(() =>
  import("components/payment/sessionTimeout")
);
const Notfound = React.lazy(() => import("components/notfound/notfound"));
const Paymenterror = React.lazy(() =>
  import("./components/payment/paymenterror")
);
const Paymentcancel = React.lazy(() =>
  import("./components/payment/paymentcancel")
);
const Paymentsuccess = React.lazy(() =>
  import("./components/payment/paymentsuccess")
);
const Payment = React.lazy(() => import("./components/payment/payment"));
const PrivacyPolicy = React.lazy(() => import("./components/privacyPolicy"));

const PaymentCCAvenue = React.lazy(() => import("./components/payment/paymentCCAvenue"));
const PaymentErrorCCAvenue = React.lazy(() => import("./components/payment/paymentErrorCCAvenue"));
const PaymentSucessCCAvenue = React.lazy(() => import("./components/payment/paymentSuccessCCAvenue"));
const CookiePolicy = React.lazy(() => import("./components/cookiePolicy"));
const TermsAndConditionPolicy = React.lazy(() => import("./components/termsandconditions/index"));
const ReturnPolicy = React.lazy(() => import("./components/returnpolicy/index"));
const PlatinumCardOffers = React.lazy(() => import("./components/platinumCardOffers/PlatinumCardOffers"));

function App() {
  //Use GTM for PageTracking
  useEffect(() => {
    initializeTagManager();
  }, []);

  usePageTracking();

  const dispatch = useDispatch();
  const { alerts, toasts } = useSelector((state) => state.appReducer);
  const cartSession = SystemConstant.cookies.get("cartSession");
  const cookieUserEmail = SystemConstant.cookies.get("userEmail");
  const country_code = SystemConstant.cookies.get("country_code");

  useEffect(() => {
    if (!cookieUserEmail) {
      removeUserCookie();
      dispatch(getSession());
      dispatch(clearUserData());
    }
    tokenExpiry(cartSession);
    dispatch(getCategories());
    dispatch(getSession());
    dispatch(getVideoUrl());
  }, []);

  useEffect(() => {
    if (country_code) {
      dispatch(getUserInfo(country_code))
    }
  }, [country_code])

  function tokenExpiry(token) {
    const response = checkAuth(token);
    if (!response) {
      removeUserCookie();
      dispatch(getSession());
      dispatch(clearUserData());
    }
    return response;
  }

  return (
    <div className="app d-flex flex-column">
      <Header />
      <div className="alert-wrap">
        {alerts.map(({ text, variant }, index) => {
          return <AlertComponent text={text} variant={variant} key={index} />;
        })}
      </div>
      {toasts.map(({ text, variant }, index) => {
        return <Toaster text={text} variant={variant} key={index} />;
      })}
      <Routes>
        <Route exact path="/" element={<Privateroute props={true} />}>
          <Route
            path="/category/:id"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Category />
              </Suspense>
            }
          />
          <Route
            path="/category"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Category />
              </Suspense>
            }
          />
          <Route
            path="/search"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <SearchPage />
              </Suspense>
            }
          />
          <Route
            path="/usercheckout"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <UserCheckout />
              </Suspense>
            }
          />
          <Route
            path="/viewproduct"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <ViewProduct />
              </Suspense>
            }
          />
          <Route
            path="/guestcheckout"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <GuestCheckout />
              </Suspense>
            }
          />
          <Route
            path="/viewcart"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <ViewCart />
              </Suspense>
            }
          />
          <Route
            path="/products"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Products />
              </Suspense>
            }
          />
          <Route
            path="/account"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Account />
              </Suspense>
            }
          />
          <Route
            path="/ordersuccess"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Ordersuccess />
              </Suspense>
            }
          />
          <Route
            path="/payment"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Payment />
              </Suspense>
            }
          />
          <Route
            path="/paymenterror"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Paymenterror />
              </Suspense>
            }
          />
          <Route
            path="/paymentcancel"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Paymentcancel />
              </Suspense>
            }
          />
          <Route
            path="/paymentsuccess"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Paymentsuccess />
              </Suspense>
            }
          />
          <Route
            path="/sessiontimeout"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <SessionTimeout />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Home />
              </Suspense>
            }
          />
        </Route>
        <Route element={<Privateroute props={false} />}>
          <Route
            path="/platinumcardoffers"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <PlatinumCardOffers />
              </Suspense>
            }
          />
          <Route
            path="/signin"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Signin />
              </Suspense>
            }
          />
          <Route
            path="/signup"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Signup />
              </Suspense>
            }
          />
          <Route
            path="/otp"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Otp props />
              </Suspense>
            }
          />
          <Route
            path="/forgetpassword"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <ForgetPassword props />
              </Suspense>
            }
          />
          <Route
            path="/newpassword"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <NewPassword props />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/aboutus"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <Aboutus />
            </Suspense>
          }
        />
        <Route
          path="/contactus"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <Contactus />
            </Suspense>
          }
        />
        <Route
          path="/privacypolicy"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/cookiepolicy"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <CookiePolicy />
            </Suspense>
          }
        />
        <Route
          path="/termsandconditions"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <TermsAndConditionPolicy />
            </Suspense>
          }
        />
        <Route
          path="/returnpolicy"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <ReturnPolicy />
            </Suspense>
          }
        />
        {/* <Route
            path="/termsandconditions"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Termsandconditions />
              </Suspense>
            }
          /> */}
        <Route
          path="/faqs"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <Faqs />
            </Suspense>
          }
        />
        {/* <Route
            path="/returnpolicy"
            element={
              <Suspense
                fallback={
                  <div className="app-loader">
                    <div className="loader"></div>
                  </div>
                }
              >
                <Returnpolicy />
              </Suspense>
            }
          /> */}
        <Route
          path="/home"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/paymentCCAvenue"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <PaymentCCAvenue />
            </Suspense>
          }
        />
        <Route
          path="/paymentsuccessccavenue"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <PaymentSucessCCAvenue />
            </Suspense>
          }
        />
        <Route
          path="/paymenterrorccavenue"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <PaymentErrorCCAvenue />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense
              fallback={
                <div className="app-loader">
                  <div className="loader"></div>
                </div>
              }
            >
              <Notfound />
            </Suspense>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
