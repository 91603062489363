import {
  SET_ALERT,
  SET_TOAST,
  API_CALL_FAILED,
  GET_CATEGORY_LIST,
  SET_SEARCH_PRODUCTS,
  SET_SEARCH_LOADER,
  SET_VIDEO_URL,
  SET_SEARCH_PRODUCT_ATTR,
} from "./types";
import SystemConstant from "constants/SystemConstant";
import axios from "axios";
import API_LIST from "./apiList";
import { checkAllCookie, getFilterURL, encodeParams } from "constants/utils";
import { getSession } from "./cartActions";
import { clearUserData } from "./signinActions";

export function getCategories() {
  return (dispatch) => {
    const headers = SystemConstant.adminRequestHeaders;
    axios
      .get(API_LIST.categoryList, { headers })
      .then((response) => {
        if (
          "children_data" in response.data &&
          response.data.children_data.length
        ) {
          dispatch(getCategoryListSuccess(response.data.children_data));
        } else {
          dispatch(setAlert(SystemConstant.failedToGetCategoryList, SystemConstant.warning));
        }
      })
      .catch(() =>
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotGetCategoriesText}`,
            SystemConstant.warning
          )
        )
      );
  };
}

export function searchProducts(name) {
  const company_code = SystemConstant.cookies.get("company_code");
  const access_code = SystemConstant.cookies.get("access_code");
  const domainVerify = SystemConstant.cookies.get("domainVerify");
  const queryParam = `&company_code=${company_code}&accessId=${access_code}`;
  return (dispatch, getState) => {
    if (!checkAllCookie() && !domainVerify) {
      dispatch(getSession());
      dispatch(clearUserData());
    }
    else {
      dispatch(setSearchLoader(true));
      const headers = SystemConstant.adminRequestHeaders;
      const filterArr = [
        {
          filter: "name",
          value: `%25${encodeParams(name)}%25`,
          condition_type: "like",
        },
        {
          filter: "sku",
          value: `%25${encodeParams(name)}%25`,
          condition_type: "like",
        }
      ];
      axios
        .get(`${API_LIST.searchProducts}${getFilterURL(filterArr)}${queryParam}`, { headers })
        .then((response) => {
          if ("data" in response) {
            const { colorList, sizeList } = getState().productReducer;
            dispatch(
              setSearchProducts(name, response.data.items, colorList, sizeList)
            );
          } else {
            console.log(SystemConstant.failedToSearchProduct);
          }
          dispatch(setSearchLoader(false));
        })
        .catch(() => {
          dispatch(setSearchLoader(false));
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotSearchProducts}`,
              SystemConstant.warning
            )
          );
        });
    }
  };
}

export function getVideoUrl() {
  const headers = SystemConstant.adminRequestHeaders;
  return (dispatch) => {
    axios
      .get(`${API_LIST.videoUrl}`, { headers })
      .then((response) => {
        let data = response.data;
        if (data?.total_count > 0 && Array.isArray(data.items)) {
          dispatch(
            setVideoUrl(data.items)
          );
        }
      })
      .catch(() =>
        dispatch(
          setAlert(
            `${SystemConstant.somethingWentWrongText}`,
            SystemConstant.warning
          )
        )
      )
  }
}

export function setSearchLoader(payload) {
  return {
    type: SET_SEARCH_LOADER,
    payload,
  };
}
export function setVideoUrl(payload) {
  return {
    type: SET_VIDEO_URL,
    payload,
  };
}

export function setSearchProducts(name, items, colorList, sizeList) {
  return {
    type: SET_SEARCH_PRODUCTS,
    payload: { name, items, colorList, sizeList },
  };
}

export function setSearchProductAttr(attrName, atttrValue, sku) {
  return {
    type: SET_SEARCH_PRODUCT_ATTR,
    payload: { attrName, atttrValue, sku },
  };
}

export function getCategoryListSuccess(response) {
  return {
    type: GET_CATEGORY_LIST,
    payload: response,
  };
}

export function setAlert(text, variant) {
  // variants can be 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark',
  return {
    type: SET_ALERT,
    payload: { text, variant },
  };
}

export function setToast(text, variant) {
  // variants can be 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark',
  return {
    type: SET_TOAST,
    payload: { text, variant },
  };
}

export function apiCallFailed(msg) {
  return {
    type: API_CALL_FAILED,
    payload: msg,
  };
}
