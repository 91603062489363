import SystemConstant from "./SystemConstant";
import axios from "axios";
import apiList from "actions/apiList";

export const CCAVENUE = "ccavenue";

export function setCookie(cookieName, cookieValue) {
  let d = new Date();
  d.setTime(d.getTime() + 12 * 60 * 60 * 1000);
  let options = { path: "/", expires: d };
  SystemConstant.cookies.set(cookieName, cookieValue, options);
}

export function getParameters(str) {
  return str
    .split("?")[1]
    .split("&")
    .reduce((acc, curr) => {
      acc[curr.split("=")[0]] = curr.split("=")[1];
      return acc;
    }, {});
}

export function encodeParams(params) {
  return encodeURIComponent(params);
}

export default function getFilterURLHelper(fieldName, fieldValue, index) {
  return `searchCriteria[filter_groups][0][filters][${index}][${fieldName}]=${fieldValue}`;
}

export function getFilterURL(filters) {
  return filters
    .map(({ filter, value = "", condition_type = "" }, index) => {
      let url = [
        `${getFilterURLHelper("field", filter, index)}`,
        getFilterURLHelper("value", value, index),
      ];
      if (condition_type) {
        url.push(getFilterURLHelper("condition_type", condition_type, index));
      }
      return url.join("&");
    })
    .join("&");
}

export function getColorSizeAndProducts(
  product,
  colorList,
  sizeList,
  productList
) {
  if (
    "extension_attributes" in product &&
    "configurable_product_options" in product.extension_attributes
  ) {
    product.extension_attributes.configurable_product_options.forEach(
      (option) => {
        if (option.attribute_id === "93") {
          product.colors = [];
          option.values.forEach((colorNumber) => {
            product.colors.push(colorList[colorNumber.value_index]);
          });
        } else if (option.attribute_id === "145") {
          product.size = [];
          option.values.forEach((sizeNumber) => {
            product.size.push(sizeList[sizeNumber.value_index]);
          });
        }
      }
    );
  } else {
    product.colors = [];
    product.size = [];
  }

  if (product.type_id === "configurable") {
    let configurableProductLinks =
      product.extension_attributes.configurable_product_links;
    product.configurableProductArray = configurableProductLinks.map((id) =>
      productList.find((product) => product.id === id)
    );
  }

  return product;
}

export function decimalHelper(amount) {
  if (amount === 0) {
    return parseFloat(amount).toFixed(2);
  } else if (amount < 0 || amount > 0) {
    return addCommasToNumber(
      (+(Math.round(parseFloat(amount) + "e+2") + "e-2")).toFixed(2)
    );
  } else return addCommasToNumber(amount);
}

export function addCommasToNumber(num) {
  let numStr = String(num);
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numStr;
}

//Todo: Commented for testing purpose
// export function decimalHelper(amount) {
//   return amount ? parseFloat(amount).toFixed(2) : amount;
// }

//function to validate email
export function validateEmail(
  email,
  compantDomainName = process.env.REACT_APP_EMAIL_DOMAIN.split(",")
) {
  const companyName = compantDomainName;
  const emailDomain = email.substring(email.lastIndexOf("@") + 1);
  const match =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    match.test(String(email).toLowerCase()) &&
    returnEmailMatch(emailDomain, companyName)
  );
}

//function to validate email
export function isValidEmail(email) {
  const match = SystemConstant.validEmailRegex;
  return match.test(String(email).toLowerCase());
}

//Helper function for validateEmail
function returnEmailMatch(domainName, domainArray) {
  let finalAnswer = false;
  for (let i = 0; i < domainArray.length; i++) {
    if (domainName.toLowerCase() === domainArray[i].toLowerCase()) {
      finalAnswer = true;
      return true;
    } else finalAnswer = false;
  }
}

// Extract url for image
export function getImageUrl(info, currentImgIndex) {
  if ("media_gallery_entries" in info) {
    if (info.selectedColor) {
      let imagePath = info.configurableProductArray.find((cp) =>
        cp.sku.includes(info.selectedColor)
      ).media_gallery_entries[currentImgIndex].file;
      return (
        SystemConstant.ImageUrlPrefix +
        (imagePath ? imagePath : SystemConstant.defaultImage)
      );
    } else {
      return (
        SystemConstant.ImageUrlPrefix +
        (info?.media_gallery_entries[currentImgIndex]?.file
          ? info.media_gallery_entries[currentImgIndex].file
          : SystemConstant.defaultImage)
      );
    }
  }
}

// Commented this as we have used optional chaining.
// Extract index of attribute
export function extractAttributeIndex(array, attributeCode, attributeName) {
  if (array?.length > 0) {
    const find = array.find((e) => {
      return e?.attribute_code === attributeCode;
    });
    if (find) {
      return find[attributeName];
    } else return "";
  } else {
    return "";
  }
}

export function extractAttributeArray(array, attributeName, objectName) {
  const find = array.find((e) => e.slider_id === attributeName);
  if (find) {
    return find[objectName];
  } else {
    return [];
  }
}

//to make nodes for html
export function renderHtml(html) {
  const e = document.createElement("div");
  e.innerHTML = html;
  return {
    __html: e.childNodes[0].innerText,
  };
}

//check auth function
export function checkAuth(token) {
  if (!token) {
    return false;
  } else {
    let auth = false;
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const tokenExpiryDate = decodedToken?.exp * 1000;
    const todayDate = new Date().getTime();
    if (tokenExpiryDate < todayDate) {
      auth = false;
    } else {
      auth = true;
    }
    return auth;
  }
}

//remove all login related cookie
export function removeUserCookie() {
  SystemConstant.cookies.remove("userEmail");
  SystemConstant.cookies.remove("cartSession");
  SystemConstant.cookies.remove("userCartToken");
  SystemConstant.cookies.remove("firstname");
}

export function checkAllCookie() {
  const cartSession = SystemConstant.cookies.get("cartSession");
  const cookieUserEmail = SystemConstant.cookies.get("userEmail");
  const cookieCartToken = SystemConstant.cookies.get("userCartToken");
  const cookieFirstName = SystemConstant.cookies.get("firstname");
  const domainVerify = SystemConstant.cookies.get("domainVerify");

  if (
    !checkAuth(cartSession) ||
    !cookieCartToken ||
    !cookieUserEmail ||
    !cookieFirstName
  ) {
    if (!domainVerify) {
      SystemConstant.cookies.remove("domainVerify");
      SystemConstant.cookies.remove("guest_quote_id");
      SystemConstant.cookies.remove("newSession");
      SystemConstant.cookies.remove("cart_id");
    }
    return false;
  } else {
    SystemConstant.cookies.remove("domainVerify");
    SystemConstant.cookies.remove("guest_quote_id");
    SystemConstant.cookies.remove("newSession");
    return true;
  }
}

export function getProductPrice(product) {
  if ("extension_attributes" in product) {
    let indexOfSpecialPrice =
      product.extension_attributes["floor_special_price"];
    if (
      indexOfSpecialPrice &&
      indexOfSpecialPrice > 0 &&
      indexOfSpecialPrice > product.price
    ) {
      return product.price;
    }
  }
  if ("configurableProductArray" in product) {
    return product.configurableProductArray.reduce(
      (acc, curr) => Math.max(acc, curr.price),
      0
    );
  } else {
    return product.price;
  }
}

export function splPrice(product) {
  if ("extension_attributes" in product) {
    let indexOfSpecialPrice =
      product.extension_attributes["floor_special_price"];
    if (
      indexOfSpecialPrice &&
      indexOfSpecialPrice > 0 &&
      indexOfSpecialPrice > product.price
    ) {
      return indexOfSpecialPrice;
    }
  }
}

export function text(product) {
  if ("extension_attributes" in product) {
    let indexOfSpecialPrice =
      product.extension_attributes["floor_special_price"];
    if (
      indexOfSpecialPrice &&
      indexOfSpecialPrice > 0 &&
      indexOfSpecialPrice > product.price
    ) {
      return SystemConstant.sarText;
    }
  }
}

/**
 * Function to validate modbile number
 *
 * @param   mobileNumber  mobileNumber.
 * @returns boolean value.
 */
export function isValidMobileNumber(mobileNumber) {
  var regex = new RegExp("^[0-9]*$");
  return regex.test(mobileNumber);
}

/**
 * Function to validate mobile number length
 *
 * @param   mobileNumber  mobileNumber.
 * @returns boolean value.
 */
export function isValidMobileNumberLength(mobileNumber) {
  return mobileNumber?.length == 9;
}

/**
 * Function to restrict Special Characters.
 *
 * @param   evt  event.
 */
export function restrictSpecialCharacters(evt) {
  if (
    (evt.which != 8 &&
      evt.which != 0 &&
      evt.which != 46 &&
      evt.which != 37 &&
      evt.which != 39 &&
      evt.which < 48) ||
    evt.which > 57
  ) {
    evt.preventDefault();
  }
}

export function returnEmail(email, domainArray) {
  const emailDomain = email.substring(email.lastIndexOf("@") + 1);
  for (let i = 0; i < domainArray.length; i++) {
    if (emailDomain.toLowerCase() === domainArray[i].toLowerCase()) {
      return domainArray[i];
    }
  }
}

export function flattenNestedArray(inputArr) {
  var result = [];
  inputArr.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a["children_data"])) {
      result = result.concat(flattenNestedArray(a["children_data"]));
    }
  });
  return result;
}

export function maxOrderQtyCheck(allowedQty, currentProductSelectionCount) {
  // rule
  if (currentProductSelectionCount <= allowedQty) {
    // allow
    return true;
  }
  return false;
}

export function setMaxQtyLookUp(value) {
  let resultToStore;
  const currentObject = getMaxQtyLookUp() ? JSON.parse(getMaxQtyLookUp()) : [];
  if (currentObject.length > 0) {
    const objectToUpdate = currentObject.find((obj) => {
      if (obj.categoryId === value.categoryId) {
        return obj;
      }
    });
    if (objectToUpdate) {
      if (value["action"] === "ADD") {
        objectToUpdate["buyQty"] = +objectToUpdate["buyQty"] + value["buyQty"];
      } else {
        objectToUpdate["buyQty"] = +objectToUpdate["buyQty"] - value["buyQty"];
      }
      resultToStore = Array.from(new Set([...currentObject, objectToUpdate]));
    } else {
      resultToStore = [...currentObject, value];
    }
  } else {
    resultToStore = [...currentObject, value];
  }
  localStorage.setItem("maxOrderQty", JSON.stringify(resultToStore));
}

export function getMaxQtyLookUp() {
  return localStorage.getItem("maxOrderQty");
}

export const convertToEmbedUrl = (url) => {
  // Extract the video ID from the URL
  const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/) ||
    url.match(/(?:https?:\/\/)?youtu\.be\/([a-zA-Z0-9_-]+)/);

  if (videoIdMatch) {
    const videoId = videoIdMatch[1];

    // Check for start time in the original URL
    const startTimeMatch = url.match(/[?&]t=(\d+)/);
    const startTime = startTimeMatch ? `?start=${startTimeMatch[1]}` : '';

    // Return the embed URL
    return `https://www.youtube.com/embed/${videoId}${startTime}`;
  }

  return url; // Return the original URL if it's not a YouTube URL
};

export function getCurrentItemPerCategory(categoryId) {
  if (getMaxQtyLookUp()) {
    const result = JSON.parse(getMaxQtyLookUp());
    const resultObj = result.find((obj) => {
      if (obj["categoryId"] === categoryId) {
        return obj;
      }
    });
    return resultObj;
  }
}

export function calculateMaxQty(categoryId, createCartObj) {
  let currentMaxQtyForCategory;
  let currentBuyQty;

  if (getMaxQtyLookUp()) {
    const curren = JSON.parse(getMaxQtyLookUp());
    const result = curren.find((object) => object["categoryId"] === categoryId);
    currentBuyQty = result;
  }

  if (getCurrentItemPerCategory(categoryId)) {
    setMaxQtyLookUp(createCartObj);
  } else {
    setMaxQtyLookUp(createCartObj);
  }
  currentMaxQtyForCategory = getCurrentItemPerCategory(categoryId);
  return currentMaxQtyForCategory;
}

export function deleteItemInLookup(value) {
  let resultToStore;
  const currentObject = getMaxQtyLookUp() ? JSON.parse(getMaxQtyLookUp()) : [];
  if (currentObject.length > 0) {
    const objectToUpdate = currentObject.find((obj) => {
      if (obj.categoryId === value.categoryId) {
        return obj;
      }
    });
    objectToUpdate["buyQty"] = +objectToUpdate["buyQty"] - value["buyQty"];
    resultToStore = Array.from(new Set([...currentObject, objectToUpdate]));
    localStorage.setItem("maxOrderQty", JSON.stringify(resultToStore));
  }
}

export function deleteItemInLookUpHelper({ cartItems, item, cust_id }) {
  const currentCategoryObj = cartItems.find(
    (cartItem) => cartItem["sku"] === item["sku"]
  );
  const currentProductItem = getCurrentItemPerCategory(
    currentCategoryObj["extension_attributes"]["product_category"]
  );
  if (currentCategoryObj && currentProductItem) {
    const createCartObjToDelete = {
      categoryId:
        currentCategoryObj["extension_attributes"]["product_category"],
      maxOderQty: currentProductItem["maxOderQty"],
      cust_id: cust_id,
      buyQty: item["qty"],
      sku: item["sku"],
      action: "SUB",
    };
    deleteItemInLookup(createCartObjToDelete);
  }
}

export async function checkCurrentStock() {
  // check if the user cart containts valid products if yes proceed or else navigate to view cart screem same Screen
  const userCartToken = SystemConstant.cookies.get("userCartToken");
  const cartId = SystemConstant.cookies.get("cart_id");
  const quoteId = cartId ? cartId : userCartToken;
  const contentToPost = { quoteId };
  let headers = SystemConstant.adminRequestHeaders;
  const queryParam = `?company_code=${SystemConstant.cookies.get(
    "company_code"
  )}`;
  let response = await axios.post(
    `${apiList.checkStockInfo}${queryParam}`,
    contentToPost,
    { headers }
  );
  return response;
}

export function deleteItemInLookupBasedOnCategory(categoryId, Qty) {
  let resultToStore;
  const currentObject = getMaxQtyLookUp() ? JSON.parse(getMaxQtyLookUp()) : [];
  if (currentObject.length > 0) {
    const objectToUpdate = currentObject.find((obj) => {
      if (obj.categoryId === categoryId) {
        return obj;
      }
    });
    objectToUpdate["buyQty"] = +objectToUpdate["buyQty"] - Qty;
    resultToStore = Array.from(new Set([...currentObject, objectToUpdate]));
    localStorage.setItem("maxOrderQty", JSON.stringify(resultToStore));
  }
}

export async function checkCCAvenuePaymentStatus(
  result_indicator,
  orderId,
  paymentMethod
) {
  // check if the user cart containts valid products if yes proceed or else navigate to view cart screem same Screen
  const contentToPost = {
    order_id: orderId,
    result_indicator: result_indicator,
    session_id: "",
    payment_method: paymentMethod,
  };
  let headers = SystemConstant.adminRequestHeaders;

  let response = await axios.post(
    `${apiList.paymentStatusCCAvenue}`,
    contentToPost,
    { headers }
  );
  return response;
}

export function findMatchingCategoryId(categoryList, itemObj) {
  if (!categoryList.length) return null;
  function flattenCategories(categories) {
    let flatList = [];
    categories.forEach((category) => {
      if (category.children_data && category.children_data.length > 0) {
        flatList = flatList.concat(flattenCategories(category.children_data));
      } else {
        flatList.push(category.entity_id);
      }
    });
    return flatList;
  }

  const allEntityIds = flattenCategories(categoryList);

  if (
    itemObj.extension_attributes &&
    itemObj.extension_attributes.category_links
  ) {
    for (const categoryLink of itemObj.extension_attributes.category_links) {
      if (allEntityIds.includes(categoryLink.category_id)) {
        return categoryLink.category_id;
      }
    }
  }
  return null;
}

export function isMatchString(a, b) {
  // Remove spaces, underscores, and convert to lowercase
  const normalizedA = a.toLowerCase();
  const normalizedB = b.replace(/[\s_]+/g, "").toLowerCase();

  return normalizedB.includes(normalizedA);
}

export function generateRandomString(length) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
