import {
  GET_COLOR_LIST,
  GET_SIZE_LIST,
  GET_SESSION,
  GET_CART_ITEMS,
  DELETE_PRODUCT_FROM_CART,
  GET_VIEW_CART_ITEMS,
  CLEAR_VIEW_CART,
} from "./types";
import SystemConstant from "constants/SystemConstant";
import axios from "axios";
import API_LIST from "./apiList";
import { checkAllCookie, setCookie, encodeParams } from "constants/utils";
import {
  setUserLoginEmail,
  setUserCartToken,
  setFirstname,
  clearUserData,
} from "./signinActions";
import { setAlert } from "./appActions";
import { resetOrder } from "./userCheckoutActions";

export async function getUserSession() {
  //guest-cart api is not used. Returning 0 to avoid this multiple call.
  return 0;
  const headers = SystemConstant.adminRequestHeaders;
  await axios
    .post(API_LIST.session, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(SystemConstant.userSessionNotPresent, err);
      return 0;
    });
}

export function getSession() {
  return (dispatch) => {
    let firstname = SystemConstant.cookies.get("firstname");
    const domainVerify = SystemConstant.cookies.get("domainVerify");
    if (firstname !== undefined) {
      let cartSession = SystemConstant.cookies.get("cartSession");
      if (!cartSession) {
        const userSession = getUserSession();
        if (userSession !== 0) {
          if (userSession && userSession.length) {
            setCookie("cartSession", userSession);
            dispatch(getSessionSuccess(userSession));
            dispatch(getCartItems(userSession));
          } else console.log(SystemConstant.failedRetrieveSession);
        }
      } else {
        dispatch(getSessionSuccess(cartSession));
        dispatch(getCartItems(cartSession));
      }

      let userEmail = SystemConstant.cookies.get("userEmail");
      if (userEmail) {
        dispatch(setUserLoginEmail(userEmail));
      }

      let userCartToken = SystemConstant.cookies.get("userCartToken");
      if (userCartToken) {
        dispatch(setUserCartToken(userCartToken));
      }

      if (firstname) {
        dispatch(setFirstname(firstname));
      }
    } else if (domainVerify === "true") {
      let userSession = '';
      dispatch(getCartItems(userSession));
    }
  };
}

export function getCartItems(cartSession) {
  return (dispatch, getState) => {
    const domainVerify = SystemConstant.cookies.get("domainVerify");
    const guestQuoteId = SystemConstant.cookies.get("guest_quote_id");
    const company_code = SystemConstant.cookies.get("company_code");
    if (!checkAllCookie() && !domainVerify) {
      dispatch(getSession());
      dispatch(clearUserData());
    }
    else {
      const userEmail = SystemConstant.cookies.get("userEmail");
      let getCartURL, headers;

      if (!userEmail) {
        // guest user
        let cartSessionTemp = cartSession
          ? cartSession
          : getState().cartReducer.cartSession || guestQuoteId;
        headers = SystemConstant.adminRequestHeaders;
        getCartURL = `${API_LIST.session}${guestQuoteId}/items`;
      } else {
        // logged in user
        headers = SystemConstant.getUserRequestHeaders();
        getCartURL = `${API_LIST.userCartItems}`;
      }
      axios
        .get(getCartURL, { headers })
        .then((response) => {
          if (response.data) {
            dispatch(getCartItemsSuccess(response.data));
          } else {
            dispatch(
              setAlert(
                `${SystemConstant.somethingWentWrongText} ${SystemConstant.failedToGetCartItemsText}`,
                SystemConstant.warning
              )
            );
          }
        })
        .catch(() =>
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.failedToGetCartItemsText}`,
              SystemConstant.warning
            )
          )
        );
    }
  };
}

export function deleteProductFromCart(quote_id, item_id, index) {
  return (dispatch) => {
    const domainVerify = SystemConstant.cookies.get("domainVerify");
    if (!checkAllCookie() && !domainVerify) {
      dispatch(getSession());
      dispatch(clearUserData());
    }
    else {
      const userEmail = SystemConstant.cookies.get("userEmail");
      let getCartURL, headers;

      if (!userEmail) {
        // guest user
        headers = SystemConstant.adminRequestHeaders;
        getCartURL = `${API_LIST.session}${quote_id}/items/${item_id}`;
      } else {
        // logged in user
        headers = SystemConstant.getUserRequestHeaders();
        getCartURL = `${API_LIST.userCartItems}/${item_id}`;
      }

      axios
        .delete(getCartURL, { headers })
        .then((response) => {
          if (response.data === true) {
            dispatch(deleteProductFromCartSuccess(index));
            dispatch(resetOrder());
          } else {
            dispatch(
              setAlert(
                `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotDeleteProduct}`,
                SystemConstant.warning
              )
            );
          }
        })
        .catch(() =>
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotDeleteProduct}`,
              SystemConstant.warning
            )
          )
        );
    }
  };
}

export function getViewCartItems(cartItems, cb) {
  const company_code = SystemConstant.cookies.get("company_code");
  const access_code = SystemConstant.cookies.get("access_code");
  const domainVerify = SystemConstant.cookies.get("domainVerify");
  const queryParam = `?company_code=${company_code}&accessId=${access_code}`;
  return (dispatch) => {
    if (!checkAllCookie() && !domainVerify) {
      cb();
      dispatch(getSession());
      dispatch(clearUserData());
    }
    else {
      const headers = SystemConstant.adminRequestHeaders;
      let skuList = cartItems.map((item) => item.sku);
      const requestArray = skuList.map((sku) =>
        axios.get(API_LIST.productById + encodeParams(sku) + queryParam, { headers })
      );
      axios
        .all(requestArray, headers)
        .then((responseArr) => {
          if (responseArr.length) {
            dispatch(getViewCartItemsSuccess(responseArr.map((res) => res.data)));
            cb();
          } else {
            dispatch(
              setAlert(
                `${SystemConstant.somethingWentWrongText} ${SystemConstant.failedToGetCartItemsText}`,
                SystemConstant.warning
              )
            );
            cb();
          }
        })
        .catch(() => {
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.failedToGetCartItemsText}`,
              SystemConstant.warning
            )
          );
          cb();
        });
    }
  };
}

export function getColorList() {
  return (dispatch) => {
    if (!checkAllCookie()) {
      dispatch(getSession());
      dispatch(clearUserData());
    }
    else {
      const headers = SystemConstant.adminRequestHeaders;
      axios
        .get(API_LIST.colorList, { headers })
        .then((response) => {
          if (response.data.length) {
            dispatch(getColorListSuccess(response.data));
          } else {
            dispatch(
              setAlert(
                `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotGetColorListText}`,
                SystemConstant.warning
              )
            );
          }
        })
        .catch(() =>
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotGetColorListText}`,
              SystemConstant.warning
            )
          )
        );
    }
  };
}

export function getSizeList() {
  return (dispatch) => {
    if (!checkAllCookie()) {
      dispatch(getSession());
      dispatch(clearUserData());
    }
    else {
      const headers = SystemConstant.adminRequestHeaders;
      axios
        .get(API_LIST.sizeList, { headers })
        .then((response) => {
          if (response.data.length) {
            dispatch(getSizeListSuccess(response.data));
          } else {
            dispatch(
              setAlert(
                `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotGetSizeListText}`,
                SystemConstant.warning
              )
            );
          }
        })
        .catch(() =>
          dispatch(
            setAlert(
              `${SystemConstant.somethingWentWrongText} ${SystemConstant.couldNotGetSizeListText}`,
              SystemConstant.warning
            )
          )
        );
    }
  };
}

export function getSessionSuccess(response) {
  return {
    type: GET_SESSION,
    payload: response,
  };
}

export function getCartItemsSuccess(response) {
  return {
    type: GET_CART_ITEMS,
    payload: response,
  };
}

export function getViewCartItemsSuccess(response) {
  return {
    type: GET_VIEW_CART_ITEMS,
    payload: response,
  };
}

export function deleteProductFromCartSuccess(index) {
  return {
    type: DELETE_PRODUCT_FROM_CART,
    payload: index,
  };
}

export function getColorListSuccess(response) {
  return {
    type: GET_COLOR_LIST,
    payload: response,
  };
}

export function getSizeListSuccess(response) {
  return {
    type: GET_SIZE_LIST,
    payload: response,
  };
}

export function clearViewCartItems() {
  return {
    type: CLEAR_VIEW_CART,
  };
}
